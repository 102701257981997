
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiResetPassword } from '@/api/setting'
import { apiLogout } from '@/api/app'

@Component
export default class ListsDetail extends Vue {
  /** S Data **/

  // 添加商城表单数据
  form: any = {
    origin_password: '', // 原密码
    password_confirm: '', // 新密码
    password: '' // 确认密码
  };

  // 表单校验
  rules = {
    origin_password: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
    password_confirm: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          !value
            ? callback(new Error('请输入密码'))
            : callback()
        },
        trigger: 'blur'
      }
    ],
    password: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          if (this.form.password_confirm) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.password_confirm) callback(new Error('两次输入密码不一致!'))
          }
          callback()
        },
        trigger: 'blur'
      }
    ]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit (formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return
      this.handleEdit()
    })
  }

  async handleEdit () {
    await apiResetPassword({ ...this.form })
    this.logout()
  }

  logout () {
    apiLogout().then(() => {
      this.$store.commit('setUserInfo', {})
      this.$store.commit('setPermission', {})
      this.$router.push('/account/login')
    })
  }
  /** E Methods **/
}
